import React from "react"
import { Link } from "gatsby"

const NavMenu = ({className}) => (
    <nav className={className}>
        <ul className="menu">
            <li><Link to="/commercials/" activeClassName="active">Commercials</Link></li>
            <li><Link to="/music-videos/" activeClassName="active">Music Videos</Link></li>
            <li><Link to="/films/" activeClassName="active">Films</Link></li>
            <li><Link to="/about/" activeClassName="active">About</Link></li>
            <li><Link to="/contact/" activeClassName="active">Contact</Link></li>
        </ul>
    </nav>
)

export default NavMenu