import React, { useState } from "react"
import { Link } from "gatsby"
import NavMenu from "./nav-menu"

export default function Layout({ children }) {
    const currentYear = new Date().getFullYear()
    const [btnState, setBtnState] = useState(false)

    const handleToggle = () => {
        setBtnState(btnState => !btnState)
    }

    let isMobileMenuActive = btnState ? 'open' : ''

    return (
        <div className="container">
            <div className="flex-wrapper">
                <header>
                    <h1><Link to="/">Bo Mirhosseni</Link></h1>
                    <button className={`mobile-nav-link-toggle ${isMobileMenuActive}`} onClick={handleToggle}>
                        <span></span>
                    </button>
                    <NavMenu className={isMobileMenuActive} />
                </header>
                {children}
            </div>
            <footer>
                ©{currentYear} BO MIRHOSSENI. All rights reserved. No part of this website may be reproduced without permission.
            </footer>
        </div>
    )
}